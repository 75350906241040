export default () => ({
    sidebarInfo: false,
    sidebarTasks: false,
    createChat: false,
    chatList: [],
    contactList: [],
    activeChat: false,
    chatMessage: {},
    chatListNext: true,
    chatListPage: 0,
    contactListNext: true,
    contactListPage: 0,
    chatMembers: {},
    addMemberPopup: false,
    pinMessage: {},
    replyMessage: {},
    selectedContacts: [],
    allContactStatus: [],
    contactsGroup: [],
    contactGroupPage: 0,
    contactGroupNext: true,
    moderate: [],
    replyMessageModal: {},
    fileList: {},
    message: {},
    messageModal: {},
    fileModal: {},
    user: {},
    sidebarActiveTab: 1,
    imagePopup: false,
    loadingInfoChat: false,
    selectImage: null,
    statusUsers: [],
    typingList: [],
    searchResult: [],
    searchStart: false,
    searchLoading: false,
    searchPage: 1,
    searchText: "",
    dialogLoading: false,
    messageListPrev: false
})